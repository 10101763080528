import { useWallets, getEmbeddedConnectedWallet } from "@privy-io/react-auth";
import "../App.css";
import {
  abiFishNFT,
  abiFishingGame,
} from "../config/abi.contract";
import { ethers } from "ethers";
import { encodeFunctionData } from "viem";
import useWrappedRPC from "./useWrappedRPC";

function useFish() {
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const { WrappedJsonProvider, sendTransactionWithGas, customChainNetwork } = useWrappedRPC();
  const { wallets } = useWallets();
  const embeddedWallet = getEmbeddedConnectedWallet(wallets);

  const provider = new WrappedJsonProvider(
    process.env.REACT_APP_RPC_BASE_SEPOLIA, customChainNetwork
  );

  const fishNFTContractAddress =
    process.env.REACT_APP_FISH_NFT_CONTRACT_ADDRESS;

  const fishingGameContractAddress =
    process.env.REACT_APP_FISHING_GAME_CONTRACT_ADDRESS;

  const contract = new ethers.Contract(
    fishNFTContractAddress,
    abiFishNFT,
    provider
  );

  const withdrawFish = async (fishIds, tokenIds) => {
    try {
      if (embeddedWallet && (await embeddedWallet.isConnected())) {
        const response = await fetch(urlEndpoint + `/trading/signature`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
          },
          body: JSON.stringify({
            contractAddress: fishNFTContractAddress,
            nameFunction: "signatureWithdrawOrMintFish",
            params: {
              fishIds: fishIds,
              tokenIds: tokenIds,
              quantity: fishIds.length
            },
          }),
        });
        const dataReponse = await response.json();

        const data = encodeFunctionData({
          abi: abiFishingGame,
          functionName: "withdrawOrMintBatchFishWithSignature",
          args: [dataReponse.req, dataReponse.signature],
        });

        const txUiConfig = {
          header: `Withdraw Fish`,
          description: `Withdraw fish`,
          buttonText: `Withdraw`,
        };

        // Construct transaction request
        const transactionRequest = {
          to: fishingGameContractAddress,
          data: data,
        };

        const tx = await sendTransactionWithGas(transactionRequest, txUiConfig);

        if (tx.status === 1) {
          const backendResponse = await verifyTxHashAndProcessWithdrawFish(
            tx.transactionHash
          );

          if (backendResponse.success) {
            return {
              success: true,
              message: "Verify successful",
              data: backendResponse.data,
            };
          } else {
            return { success: false, message: "Verify transaction failed" };
          }
        } else {
          console.error("Transaction failed:", tx);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send transaction failed:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const depositFish = async (tokenIds) => {
    try {
      if (embeddedWallet && (await embeddedWallet.isConnected())) {
        const isApproved = await contract.isApprovedForAll(
          embeddedWallet.address,
          fishingGameContractAddress
        );

        if (!isApproved) {
          const approvalData = encodeFunctionData({
            abi: abiFishNFT,
            functionName: "setApprovalForAll",
            args: [fishingGameContractAddress, true],
          });

          await sendTransactionWithGas(
            {
              to: fishNFTContractAddress,
              data: approvalData,
            },
            {
              header: "Approve all Fish",
              description: `Approve all fish for Fishing Game`,
              buttonText: "Approve all",
            }
          );
        }

        const data = encodeFunctionData({
          abi: abiFishingGame,
          functionName: "stakeFish",
          args: [tokenIds],
        });

        const tokenIdsList = tokenIds.join(", ");
        const txUiConfig = {
          header: `Deposit Fish`,
          description: `Deposit Fish IDs: ${tokenIdsList}`,
          buttonText: `Deposit`,
        };

        // Construct transaction request
        const transactionRequest = {
          to: fishingGameContractAddress,
          data: data,
        };

        const txReceipt = await sendTransactionWithGas(
          transactionRequest,
          txUiConfig
        );

        if (txReceipt.status === 1) {
          const backendResponse = await verifyTxHashAndProcessDepositFish(
            txReceipt.transactionHash
          );

          if (backendResponse.success) {
            return {
              success: true,
              message: "Verify successful",
              data: backendResponse.data,
            };
          } else {
            return { success: false, message: "Verify transaction failed" };
          }
        } else {
          console.error("Transaction failed:", txReceipt);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send transaction failed:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const cooking = async (stakedTokenIds, tokenIds) => {
    try {
      if (embeddedWallet && (await embeddedWallet.isConnected())) {
        const data = encodeFunctionData({
          abi: abiFishingGame,
          functionName: "cooking",
          args: [stakedTokenIds, tokenIds],
        });

        const tokenIdsList = tokenIds.join(", ");
        const txUiConfig = {
          header: `Cooking Fish`,
          description: `Cooking Fish IDs: ${tokenIdsList}`,
          buttonText: `Cooking`,
        };

        // Construct transaction request
        const transactionRequest = {
          to: fishingGameContractAddress,
          data: data,
        };

        const txReceipt = await sendTransactionWithGas(
          transactionRequest,
          txUiConfig
        );

        if (txReceipt.status === 1) {
          return {
            success: true,
            message: "Verify successful",
            transactionHash: txReceipt.transactionHash,
          };
        } else {
          console.error("Transaction failed:", txReceipt);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send transaction failed:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const verifyTxHashAndProcessWithdrawFish = async (txHash) => {
    try {
      const response = await fetch(urlEndpoint + "/trading/withdraw-nft-fish", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
        },
        body: JSON.stringify({ transactionHash: txHash }),
      });
      const data = await response.json();

      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Error when sending transactionHash to backend:", error);
      return { success: false, message: error.message };
    }
  };

  const verifyTxHashAndProcessDepositFish = async (txHash) => {
    try {
      const response = await fetch(urlEndpoint + "/trading/deposit-nft-fish", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
        },
        body: JSON.stringify({ transactionHash: txHash }),
      });
      const data = await response.json();

      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Error when sending transactionHash to backend:", error);
      return { success: false, message: error.message };
    }
  };

  return { withdrawFish, depositFish, cooking };
}

export default useFish;
