import { BigNumber } from "ethers";
import { JsonRpcProvider } from "@ethersproject/providers";
import { resolveProperties } from "@ethersproject/properties";
import { usePrivy } from "@privy-io/react-auth";

function useWrappedRPC() {
  const { sendTransaction } = usePrivy();
  class WrappedJsonProvider extends JsonRpcProvider {
    async getFeeData() {
      const { block, gasPrice } = await resolveProperties({
        block: this.getBlock("latest"),
        gasPrice: this.getGasPrice().catch((_) => {
          return null;
        }),
      });

      let lastBaseFeePerGas = null,
        maxFeePerGas = null,
        maxPriorityFeePerGas = null;

      if (block && block.baseFeePerGas) {
        lastBaseFeePerGas = block.baseFeePerGas;
        maxPriorityFeePerGas =
          gasPrice != null ? gasPrice : BigNumber.from("1500000000");
        maxFeePerGas = block.baseFeePerGas.mul(2).add(maxPriorityFeePerGas);
      }

      return {
        lastBaseFeePerGas,
        maxFeePerGas,
        maxPriorityFeePerGas,
        gasPrice,
      };
    }
  }

  const sendTransactionWithGas = async (transactionRequest, txUiConfig) => {
    const provider = new WrappedJsonProvider(
      process.env.REACT_APP_RPC_BASE_SEPOLIA
    );

    const { maxFeePerGas, maxPriorityFeePerGas } = await provider.getFeeData();

    const tx = {
      ...transactionRequest,
      maxFeePerGas: maxFeePerGas.toNumber(),
      maxPriorityFeePerGas: maxPriorityFeePerGas.toNumber(),
    };

    return await sendTransaction(tx, txUiConfig);
  };

  const customChainNetwork = {
    name: process.env.REACT_CHAIN_NAME,
    chainId: parseInt(process.env.REACT_APP_CHAIN_ID || '0', 10),
  };

  return { WrappedJsonProvider, sendTransactionWithGas, customChainNetwork };
}

export default useWrappedRPC;
