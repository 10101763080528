import { useWallets, getEmbeddedConnectedWallet } from "@privy-io/react-auth";
import "../App.css";
import { encodeFunctionData } from "viem";
import { abiRodNFT, abiFishingGame } from "../config/abi.contract";
import { ethers } from "ethers";
import useWrappedRPC from "./useWrappedRPC";

function useRod() {
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const { WrappedJsonProvider, sendTransactionWithGas, customChainNetwork } = useWrappedRPC();
  const { wallets } = useWallets();
  const embeddedWallet = getEmbeddedConnectedWallet(wallets);
  const rodNFTContractAddress = process.env.REACT_APP_ROD_NFT_CONTRACT_ADDRESS;

  const fishingGameContractAddress =
    process.env.REACT_APP_FISHING_GAME_CONTRACT_ADDRESS;

  const provider = new WrappedJsonProvider(
    process.env.REACT_APP_RPC_BASE_SEPOLIA, customChainNetwork
  );

  const rodNFTContract = new ethers.Contract(
    rodNFTContractAddress,
    abiRodNFT,
    provider
  );

  const depositRod = async (rodIds) => {
    try {
      if (embeddedWallet && (await embeddedWallet.isConnected())) {
        const isApproved = await rodNFTContract.isApprovedForAll(
          embeddedWallet.address,
          fishingGameContractAddress
        );

        if (!isApproved) {
          const approvalData = encodeFunctionData({
            abi: abiRodNFT,
            functionName: "setApprovalForAll",
            args: [fishingGameContractAddress, true],
          });

          await sendTransactionWithGas(
            {
              to: rodNFTContractAddress,
              data: approvalData,
            },
            {
              header: "Approve all Rod",
              description: `Approve all rod for Fishing Game`,
              buttonText: "Approve all",
            }
          );
        }

        const data = encodeFunctionData({
          abi: abiFishingGame,
          functionName: "stakeRod",
          args: [rodIds],
        });

        const rodIdsList = rodIds.join(", ");
        const txUiConfig = {
          header: `Deposit Rod`,
          description: `Deposit Rod IDs: ${rodIdsList}`,
          buttonText: `Deposit`,
        };

        // Construct transaction request
        const transactionRequest = {
          to: fishingGameContractAddress,
          data: data,
        };

        const txReceipt = await sendTransactionWithGas(
          transactionRequest,
          txUiConfig
        );

        if (txReceipt.status === 1) {
          const backendResponse = await verifyTxHashAndProcessDepositRod(
            txReceipt.transactionHash
          );

          if (backendResponse.success) {
            return {
              success: true,
              message: "Verify successful",
              data: backendResponse.data,
            };
          } else {
            return { success: false, message: "Verify transaction failed" };
          }
        } else {
          console.error("Transaction failed:", txReceipt);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send transaction failed:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const withdrawRod = async (rodIds) => {
    try {
      if (embeddedWallet && (await embeddedWallet.isConnected())) {
        const data = encodeFunctionData({
          abi: abiFishingGame,
          functionName: "unstakeRod",
          args: [rodIds],
        });

        const rodIdsList = rodIds.join(", ");
        const txUiConfig = {
          header: `Withdraw Rod`,
          description: `Withdraw Rod IDs: ${rodIdsList}`,
          buttonText: `Withdraw`,
        };

        // Construct transaction request
        const transactionRequest = {
          to: fishingGameContractAddress,
          data: data,
        };

        const txReceipt = await sendTransactionWithGas(
          transactionRequest,
          txUiConfig
        );

        if (txReceipt.status === 1) {
          const backendResponse = await verifyTxHashAndProcessWithdrawRod(
            txReceipt.transactionHash
          );

          if (backendResponse.success) {
            return {
              success: true,
              message: "Verify successful",
              data: backendResponse.data,
            };
          } else {
            return { success: false, message: "Verify transaction failed" };
          }
        } else {
          console.error("Transaction failed:", txReceipt);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send transaction failed:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const repairRod = async (rodId) => {
    try {
      if (embeddedWallet && (await embeddedWallet.isConnected())) {
        const response = await fetch(urlEndpoint + `/trading/signature`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
          },
          body: JSON.stringify({ 
            contractAddress: rodNFTContractAddress,
            nameFunction: "signatureRepairRod",
            params: {
              tokenId: rodId
            }
           }),
        });
        const dataReponse = await response.json();

        const data = encodeFunctionData({
          abi: abiRodNFT,
          functionName: "repairWithSignature",
          args: [dataReponse.req, dataReponse.signature],
        });

        const txUiConfig = {
          header: `Repair Rod`,
          description: `Repair Rod ID: ${rodId}`,
          buttonText: `Repair`,
        };

        // Construct transaction request
        const transactionRequest = {
          to: rodNFTContractAddress,
          data: data,
        };

        const txReceipt = await sendTransactionWithGas(
          transactionRequest,
          txUiConfig
        );

        if (txReceipt.status === 1) {
          const backendResponse = await verifyTxHashAndProcessRepairRod(
            txReceipt.transactionHash, rodId
          );

          if (backendResponse.success) {
            return {
              success: true,
              message: "Verify successful",
              data: backendResponse.data,
            };
          } else {
            return { success: false, message: "Verify transaction failed" };
          }
        } else {
          console.error("Transaction failed:", txReceipt);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send transaction failed:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const verifyTxHashAndProcessDepositRod = async (txHash) => {
    try {
      const response = await fetch(urlEndpoint + "/trading/deposit-nft-rod", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
        },
        body: JSON.stringify({ transactionHash: txHash }),
      });
      const data = await response.json();

      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Error when sending transactionHash to backend:", error);
      return { success: false, message: error.message };
    }
  };

  const verifyTxHashAndProcessWithdrawRod = async (txHash) => {
    try {
      const response = await fetch(urlEndpoint + "/trading/withdraw-nft-rod", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
        },
        body: JSON.stringify({ transactionHash: txHash }),
      });
      const data = await response.json();

      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Error when sending transactionHash to backend:", error);
      return { success: false, message: error.message };
    }
  };

  const verifyTxHashAndProcessRepairRod = async (txHash, tokenId) => {
    try {
      const response = await fetch(urlEndpoint + "/trading/repair-nft-rod", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
        },
        body: JSON.stringify({ transactionHash: txHash, tokenId }),
      });
      const data = await response.json();

      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Error when sending transactionHash to backend:", error);
      return { success: false, message: error.message };
    }
  };

  return { depositRod, withdrawRod, repairRod };
}

export default useRod;
