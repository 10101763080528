import {
  useWallets,
  getEmbeddedConnectedWallet,
} from "@privy-io/react-auth";
import "../App.css";
import { encodeFunctionData } from "viem";
import { abiChestNFT, abiFishingGame } from "../config/abi.contract";
import { ethers } from "ethers";
import useWrappedRPC from "./useWrappedRPC";

function useChest() {
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const { WrappedJsonProvider, sendTransactionWithGas, customChainNetwork } = useWrappedRPC();
  const { wallets } = useWallets();
  const embeddedWallet = getEmbeddedConnectedWallet(wallets);
  const fishingGameContractAddress =
    process.env.REACT_APP_FISHING_GAME_CONTRACT_ADDRESS;

  const chestNFTContractAddress =
    process.env.REACT_APP_CHEST_NFT_CONTRACT_ADDRESS;

    const provider = new WrappedJsonProvider(
      process.env.REACT_APP_RPC_BASE_SEPOLIA, customChainNetwork
    );
    const erc721Contract = new ethers.Contract(
      chestNFTContractAddress,
      abiChestNFT,
      provider
    );

  const openNFTChest = async (chestId) => {
    try {
      if (embeddedWallet && (await embeddedWallet.isConnected())) {
        const isApproved = await erc721Contract.isApprovedForAll(
          embeddedWallet.address,
          fishingGameContractAddress
        );

        if (!isApproved) {
          const approvalData = encodeFunctionData({
            abi: abiChestNFT,
            functionName: "setApprovalForAll",
            args: [fishingGameContractAddress, true],
          });

          await sendTransactionWithGas(
            {
              to: chestNFTContractAddress,
              data: approvalData,
            },
            {
              header: "Approve all chests",
              description: `Approve all chests for Fishing Game`,
              buttonText: "Approve all",
            }
          );
        }

        const data = encodeFunctionData({
          abi: abiFishingGame,
          functionName: "openChest",
          args: [chestId],
        });

        const txUiConfig = {
          header: `Open Chest`,
          description: `Open chest with ID ${chestId}`,
          buttonText: `Open`,
        };

        // Construct transaction request
        const transactionRequest = {
          to: fishingGameContractAddress,
          data: data,
        };

        const txReceipt = await sendTransactionWithGas(transactionRequest, txUiConfig);

        if (txReceipt.status === 1) {
          const backendResponse = await verifyTxHashAndProcessOpenChest(
            txReceipt.transactionHash
          );

          if (backendResponse.success) {
            return {
              success: true,
              message: "Verify successful",
              data: backendResponse.data,
            };
          } else {
            return { success: false, message: "Verify transaction failed" };
          }
        } else {
          console.error("Transaction failed:", txReceipt);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send transaction failed:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const verifyTxHashAndProcessOpenChest = async (txHash) => {
    try {
      const response = await fetch(urlEndpoint + "/trading/open-nft-chest", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
        },
        body: JSON.stringify({ transactionHash: txHash }),
      });
      const data = await response.json();

      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Error when sending transactionHash to backend:", error);
      return { success: false, message: error.message };
    }
  };

  return { openNFTChest };
}

export default useChest;
